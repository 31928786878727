import {Icon20Users3} from "@vkontakte/icons";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {Avatar, Button, Card, Cell, Header, Separator, Spacing, Spinner} from "@vkontakte/vkui";
import React, {useEffect, useState} from 'react';
import {getTopWinners} from "../../services/player";

// Rating - блок для отображения рейтинга игроков
export function Rating() {
    const routeNavigator = useRouteNavigator();
    const [players, setPlayers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getTopWinners().then(resp => {
            const data = resp.data.response;
            if (data === null) {
                return;
            }

            const players = data.winners.map(player => {
                return (<Cell before={<Avatar src={player.avatar}/>}
                              key={player.id}
                              subtitle={"Побед: " + player.won_games + " Поражений: " + player.lose_games}
                              disabled={true}
                >
                    {player.name}
                </Cell>)
            })
            setPlayers(players);
        }).catch(err => {
            console.log("getTopWinners", err);
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    const clickHandler = () => {
        // Переходим на страницу с рейтингом
        routeNavigator.push('/rating');
    }

    return (<div className={"flex w-full flex-col max-w-md"}>
        {/* Ожидаем загрузку данных */}
        {isLoading === true && <Spinner size={"large"}/>}

        {/* Отображаем контент */}
        {!isLoading && <>
            <Card>
                <Header>Лучшие игроки</Header>

                {players.length <= 0 && (<div className={"flex w-full items-center justify-center mb-3"}>
                    <p className={"text-xs"}>Рейтинг пока пуст</p>
                </div>)}

                {players.length > 0 && <>
                    {players}
                    <Spacing size={24}>
                        <Separator/>
                    </Spacing>
                    <Button
                        onClick={clickHandler}
                        before={<Icon20Users3/>}
                        appearance={"neutral"}
                        size={"s"}
                        align={"center"}
                        stretched={true}
                        mode={"link"}
                    >
                        Весь рейтинг
                    </Button>
                    <Spacing size={12}/>
                </>}
            </Card>
        </>}
    </div>);
}
