import {parseLaunchParams} from "../../utils/launch_params";
import {useState} from "react";
import {AddNewAttemptBonusPrice} from "../../model/prices";
import GameStore, {maxAttempts} from "../../store/game";
import {OpenErrorSnackbar} from "../../components/SnackBar/SnackBar";
import {addNewAttempt} from "../../services/game";
import BonusStore from "../../store/bonus";
import {Icon20SparkleOutline, Icon36CoinsStacks2Outline} from "@vkontakte/icons";
import {Button} from "@vkontakte/vkui";
import {observer} from "mobx-react-lite";

// Вкладка со счётом бонусов
export const Bonuses = observer(({}) => {
    const params = parseLaunchParams();
    const [snackbar, setSnackbar] = useState(null);

    const addAttemptHandler = () => {
        if (BonusStore.coins < AddNewAttemptBonusPrice) {
            setSnackbar(OpenErrorSnackbar("Не хватает монет", () => {
                setSnackbar(null)
            }));
            return;
        }

        if (GameStore.attemptHistory.length > maxAttempts) {
            setSnackbar(OpenErrorSnackbar("Вы использовали максимум попыток отгадать слово", () => {
                setSnackbar(null)
            }));
            return;
        }

        addNewAttempt(params.vk_user_id, GameStore.gameID).then(resp => {
            GameStore.addBonusAttempt();
            BonusStore.credit(AddNewAttemptBonusPrice);
        });
    };

    return (<>
        {snackbar}
        <div className={"flex flex-row w-full bg-gray-800 px-4 py-2 items-center"}>
            {/* Бонусный счет */}
            <div className={"flex flex-row p-2 items-center"}>
                <Icon36CoinsStacks2Outline className={"text-yellow-500"}/>
                <span className={"ml-2 font-bold text-gray-300"}>{BonusStore.coins}</span>
                <span className={"ml-2 text-sm text-gray-300"}>монет</span>
                {/*<Icon20AddCircleFillGreen onClick={handleDebitCoins} className={"ml-2 cursor-pointer"}/>*/}
            </div>

            {/* Подсказки */}
            <div className={"flex flex-row bg-gray-800 p-2 items-center"}>
                {(GameStore.attemptHistory.length < maxAttempts && GameStore.isWin === null) &&
                    <Button appearance={"positive"}
                            size={"s"}
                            before={<Icon20SparkleOutline/>}
                            mode={"outline"}
                            onClick={addAttemptHandler}
                    >
                        +1 попытка
                    </Button>}
            </div>
        </div>
    </>)
});