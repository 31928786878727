import {Icon24CircleSmall, Icon36CoinsStacks2Outline} from "@vkontakte/icons";
import {ModalCard, Separator, Spacing} from "@vkontakte/vkui";
import {observer} from "mobx-react-lite";
import React from 'react';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import {ShareButton} from "../../components/ShareButton/ShareButton";
import {GAME_SUCCESS_FINISH_MODAL} from "../../routing/routing";
import BonusStore from "../../store/bonus";
import GameStore from "../../store/game";

// Модальное окно, которые мы показываем после того как игрок выиграл
export const SuccessFinishGameModal = observer(() => {
    const {width, height} = useWindowSize();

    return (
        <>
            <Confetti
                recycle={false}
                width={width}
                height={height}
            />

            <ModalCard
                id={GAME_SUCCESS_FINISH_MODAL}
                header={<p>Вы отгадали слово!</p>}
                dismissButtonMode={"inside"}
            >
                <Spacing size={24}><Separator/></Spacing>

                <div className={"mt-2 flex justify-center items-center flex-col text-gray-800"}>
                    <div className={"flex w-full flex-row text-center"}>
                        <div className={"w-1/2"}>
                            <p className={"font-bold"}>Количество попыток: </p>
                            <div className={"flex flex-row justify-center items-center"}>
                                <Icon24CircleSmall width={24} height={24} className={"text-green-600"}/>
                                <span
                                    className={"ml-1 text-xl"}>{GameStore.attemptNumber}/{GameStore.attemptHistory.length}</span>
                            </div>
                        </div>
                        <div className={"w-1/2"}>
                            <p className={"font-bold"}>Монеты:</p>
                            <div className={"flex flex-row justify-center items-center"}>
                                <Icon36CoinsStacks2Outline width={24} height={24} className={"text-yellow-500"}/>
                                <span className={"ml-1 text-xl"}>{BonusStore.coins}</span>
                                {GameStore.earnedCoins > 0 && (
                                    <span className={"ml-1 text-sm text-green-400"}>+{GameStore.earnedCoins}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <Spacing size={24}><Separator/></Spacing>

                <div className={"flex justify-center items-center mt-2"}>
                    <ShareButton/>
                </div>
            </ModalCard>
        </>
    )
});
