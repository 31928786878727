import {makeAutoObservable} from "mobx";


class Bonus {
    // Число монеток, доступных пользователю
    coins = 0;

    constructor() {
        makeAutoObservable(this)
    }

    setCoins(value) {
        this.coins = value;
    }

    debit(value) {
        this.coins += value;
    }

    credit(value) {
        if (this.coins >= value) {
            this.coins -= value;
        }
    }
}


const BonusStore = new Bonus();
export default BonusStore;