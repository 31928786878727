import {Icon16Smile, Icon28WarningTriangleOutline} from "@vkontakte/icons";
import {useFirstPageCheck, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {PanelHeader, PanelHeaderBack, ScreenSpinner, SplitCol, SplitLayout} from "@vkontakte/vkui";
import "../../css/Game.css";
import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from 'react';
import Countdown from "../../components/Countdown/Countdown";
import {OpenErrorSnackbar} from "../../components/SnackBar/SnackBar";
import {GAME_FAILED_FINISH_MODAL, GAME_SUCCESS_FINISH_MODAL} from "../../routing/routing";
import {checkUserWord, createGame} from "../../services/game";
import {getPlayerInfo} from "../../services/player";
import BonusStore from "../../store/bonus";
import GameStore, {maxAttempts, maxLetters} from "../../store/game";
import {parseLaunchParams} from "../../utils/launch_params";
import {timeToMidnight} from "../../utils/time";
import {Bonuses} from "./Bonuses";
import {GameBoard} from "./GameBoard";
import {Keyboard} from "./Keyboard";

// Неизвестная ошибка при загрузке игры
const errUnknownLoadingGame = "ERR_INTERNAL_LOADING_GAME_ERROR"
const errPlayerDailyAttemptsAreEmpty = "ERR_PLAYER_DAILY_ATTEMPTS_ARE_EMPTY"

// Главный компонент игры
export const Game = observer(() => {
    // Роутинг
    const routeNavigator = useRouteNavigator();
    const isFirstPage = useFirstPageCheck();

    // Состояние компонента
    const [popout, setPopout] = useState(<ScreenSpinner size="large"/>);
    const [snackbar, setSnackbar] = useState(null);
    const [loadingGameErrorCode, setLoadingGameErrorCode] = useState("");

    useEffect(() => {
        const params = parseLaunchParams();

        // Сбрасываем состояние
        GameStore.flush();

        // Создаём игру
        createGame(params.vk_user_id).then(resp => {
            const game = resp.data.response;
            console.log("creating game: ", game);
            GameStore.setGameID(game.id);

            // Есть сохранённая игра
            if (game.attempts_json.length > 0) {
                const attempts = JSON.parse(game.attempts_json);
                attempts.forEach((value, i) => {
                    GameStore.addAttemptToHistory(value);
                    GameStore.incrementAttemptNumber();
                });
            }

            if (game.hints_json.length > 0) {
                const hints = JSON.parse(game.hints_json);
                if (hints.indexOf("attempt") > -1) {
                    GameStore.addBonusAttempt();
                }
            }
        }).catch(err => {
            if (err.response !== undefined) {
                const errCode = err.response.data.response.code;
                const errMsg = err.response.data.response.error;
                setLoadingGameErrorCode(errCode);
                setSnackbar(OpenErrorSnackbar(errMsg, () => {
                    setSnackbar(null)
                }));
                return;
            }

            setLoadingGameErrorCode(errUnknownLoadingGame);
        }).finally(() => {
            setPopout(null);
        });

        // Запрашиваем информацию об игроке
        getPlayerInfo(params.vk_user_id).then(resp => {
            const player = resp.data.response;
            BonusStore.setCoins(player.coins);
            GameStore.setDailyAttempts(player.daily_attempts);
        });
    }, []);

    // Обработчик нажатия на кнопку с буквой на клавиатуре
    const handleChooseLetter = e => {
        if (GameStore.attempt.length >= maxLetters || GameStore.attemptNumber > maxAttempts) return;

        const chosenLetter = e.currentTarget.dataset.letter;
        GameStore.addLetterToAttempt(chosenLetter);
    };

    // Обработчик нажатия на кнопку проверки введённого слова
    const handleDoneButton = () => {
        const params = parseLaunchParams();

        // Валидация
        if (GameStore.isWin === true) {
            console.log("Игра уже окончена, результат: ", GameStore.isWin);
            return;
        }

        if (GameStore.attempt.length < maxLetters) {
            setSnackbar(OpenErrorSnackbar("Слово слишком короткое", () => {
                setSnackbar(null)
            }));
            return;
        }

        // Формируем слово из букв и проверяем вхождение букв из слова пользователя в загаданном слове
        let userWord = "";
        GameStore.attempt.map((letter, i) => {
            userWord += letter.value;
        });

        checkUserWord(params.vk_user_id, GameStore.gameID, userWord).then(resp => {
            const game = resp.data.response;

            // Проверяем, что игра выиграна
            if (game.status === "won") {
                GameStore.setIsWin(true);
                GameStore.setEarnedCoins(game.earned_coins);
                BonusStore.setCoins(BonusStore.coins + game.earned_coins);

                // Открываем модальное окно
                routeNavigator.showModal(GAME_SUCCESS_FINISH_MODAL);
            } else if (game.status === "lose") {
                GameStore.setIsWin(false);
                GameStore.setWord(game.word);

                // Открываем модальное окно
                routeNavigator.showModal(GAME_FAILED_FINISH_MODAL);
            }

            const attempts = JSON.parse(game.attempts_json);
            const lastAttempt = attempts[attempts.length - 1];

            // Обновляем состояние
            GameStore.addAttemptToHistory(lastAttempt);
            GameStore.flushAttempt();
            GameStore.incrementAttemptNumber();
        }).catch(err => {
            console.log("checkUserWord error: ", err)
            const errMsg = err.response.data.response.error;
            console.log(errMsg);

            setSnackbar(OpenErrorSnackbar(errMsg, () => {
                setSnackbar(null)
            }));
        });
    };

    // Обработчик нажатия на кнопку удаления символа, при вводе пользователем слова
    const handleBackspaceButton = () => {
        if (GameStore.attempt.length === 0) {
            console.log("Нечего удалять");
            return;
        }

        GameStore.deleteLastLetterFromAttempt();
    };

    const handleBackButton = () => {
        return isFirstPage ? routeNavigator.push('/') : routeNavigator.replace('/');
    }

    return <>
        {snackbar}

        <PanelHeader
            before={<PanelHeaderBack className={"[&>svg]:p-0"} onClick={handleBackButton}/>}
            separator={null}
        >
            5 букв
        </PanelHeader>

        <SplitLayout popout={popout}>
            <SplitCol>
                <div className={"mx-auto"}>

                    {/* У игрока закончились дневные попытки */}
                    {loadingGameErrorCode === errPlayerDailyAttemptsAreEmpty && <div
                        className={"flex flex-row mx-auto pt-3 py-2 items-center justify-center max-w-md bg-white rounded-xl"}>
                        <Countdown date={timeToMidnight()}/>
                    </div>}

                    {/* Другая ошибка загрузки игры */}
                    {loadingGameErrorCode === errUnknownLoadingGame && <>
                        <div className={"flex flex-row px-4 py-2 items-center justify-center"}>
                            <div className={"flex w-full bg-gray-800 rounded px-4 py-6 items-center gap-3"}>
                                <Icon28WarningTriangleOutline className={"text-red-600"}/>
                                <p className={"bold text-gray-300"}>
                                    При загрузке игры произошла неизвестная ошибка, но мы-то о ней уже знаем и
                                    работаем над исправлением
                                    <Icon16Smile className={"text-yellow-600 inline ml-2 -mt-0.5"}/>
                                </p>
                            </div>
                        </div>
                    </>}

                    {/* Ошибок загрузки игры нет */}
                    {loadingGameErrorCode === "" && <>
                        <Bonuses/>
                        <GameBoard/>

                        {(GameStore.isWin !== null) &&
                            <div className={"flex flex-row px-4 py-2 items-center justify-center"}>
                                <div
                                    className={"flex w-full md:w-1/3 bg-gray-800 rounded px-4 py-6 items-center justify-center"}>
                                    <p className={"bold text-gray-300"}>Игра окончена</p>
                                </div>
                            </div>}

                        {(GameStore.isWin === null) && <Keyboard onChooseLetter={handleChooseLetter}
                                                                 onDoneButton={handleDoneButton}
                                                                 onBackspaceButton={handleBackspaceButton}/>}
                    </>}
                </div>
            </SplitCol>
        </SplitLayout>
    </>
});
