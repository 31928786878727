import {useActiveVkuiLocation, useGetPanelForView, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {ModalRoot, Panel, Root, SplitCol, SplitLayout, View} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import React from 'react';
import {FailedFinishGameModal} from "./modals/Game/FailedFinishGame";
import {SuccessFinishGameModal} from "./modals/Game/SucessFinishGame";
import {Game} from './panels/Game/Game';
import Home from './panels/Home/Home';
import Rating from './panels/Rating';
import {
    DEFAULT_VIEW,
    GAME_FAILED_FINISH_MODAL,
    GAME_PANEL,
    GAME_SUCCESS_FINISH_MODAL,
    HOME_PANEL,
    RATING_PANEL
} from "./routing/routing";
import {showAdvertising} from "./utils/advertising";

function App() {
    const routeNavigator = useRouteNavigator();
    const {view: activeView, modal: activeModal} = useActiveVkuiLocation();
    const activePanel = useGetPanelForView(DEFAULT_VIEW);

    // Обработчик закрытия модальных окон
    const closeModals = (modalID) => {
        switch (modalID) {
            case GAME_SUCCESS_FINISH_MODAL:
            case GAME_FAILED_FINISH_MODAL:
                showAdvertising();
        }

        return routeNavigator.hideModal();
    }

    const modals = (
        <ModalRoot activeModal={activeModal} onClose={(modalID) => {
            closeModals(modalID)
        }}>
            <SuccessFinishGameModal nav={GAME_SUCCESS_FINISH_MODAL}/>
            <FailedFinishGameModal nav={GAME_FAILED_FINISH_MODAL}/>
        </ModalRoot>
    );

    return (
        <SplitLayout modal={modals}>
            <SplitCol>
                <Root activeView={activeView}>
                    <View nav={DEFAULT_VIEW} activePanel={activePanel} onSwipeBack={() => routeNavigator.back()}>
                        <Panel nav={HOME_PANEL}><Home/></Panel>
                        <Panel nav={GAME_PANEL}><Game/></Panel>
                        <Panel nav={RATING_PANEL}><Rating/></Panel>
                    </View>
                </Root>
            </SplitCol>
        </SplitLayout>
    );
}

export default App;
