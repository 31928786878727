// parseLaunchParams разбирает параметры поиска
export function parseLaunchParams() {
    const rawParams = window.location.search;
    const listParams = rawParams.slice(1, rawParams.length - 1).split("&");

    const result = {};
    if (listParams.length === 0) {
        return result;
    }

    listParams.forEach(item => {
        const [key, value] = item.split("=");
        result[key] = value;
    })
    return result;
}