import axios from "axios";
import {createURL} from "./client";

// getPlayerInfo получает информацию о пользователе
export async function getPlayerInfo(playerID) {
    return await axios.get(createURL("/api/player/?vk_user_id=" + parseInt(playerID)));
}

// getTopWinners получает топ-3 игроков по количеству выигранных игр
export async function getTopWinners() {
    return await axios.get(createURL("/api/player/top-winners"));
}

// getPlayerRating получает рейтинг игроков по количеству выигранных игр
export async function getPlayerRating() {
    return await axios.get(createURL("/api/player/rating"));
}

// add3DailyAttempts начисляет игроку 3 бонусных жизни за монеты
export async function add3DailyAttempts() {
    return await axios.post(createURL("/api/player/add-3-daily-attempts"));
}