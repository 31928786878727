import {Spacing} from '@vkontakte/vkui';
import React from 'react';
import {Logotype} from "./Logotype";
import {Rating} from "./Rating";
import {Recommendation} from "./Recommendation";
import {StartButton} from "./StartButton";
import {Subscription} from "./Subscription";

function Home() {
    return (<div className={"flex flex-col p-4 items-center mx-auto"}>
        <Logotype/>
        <Spacing size={48}/>
        <StartButton/>
        <Recommendation/>
        <Spacing size={24}/>
        <Rating/>
        <Spacing size={24}/>
        <Subscription/>
        <Spacing size={12}/>
    </div>)
}

export default Home;

