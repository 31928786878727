import {ModalCard, Separator, Spacing} from "@vkontakte/vkui";
import {observer} from "mobx-react-lite";
import React from 'react';
import {ShareButton} from "../../components/ShareButton/ShareButton";
import {GAME_FAILED_FINISH_MODAL} from "../../routing/routing";
import GameStore from "../../store/game";

// Модальное окно, которые мы показываем после того как игрок проиграл
export const FailedFinishGameModal = observer(() => {
    return (<ModalCard
        id={GAME_FAILED_FINISH_MODAL}
        header={<p>Поражение!</p>}
        dismissButtonMode={"inside"}
    >
        <Spacing size={24}><Separator/></Spacing>

        <div className={"mt-2 flex justify-center items-center flex-col text-gray-800"}>
            <div className={"flex w-full flex-col text-center"}>
                <div className={"w-full"}>
                    <p className={"font-bold"}>Количество попыток: </p>
                    <div className={"flex flex-row justify-center items-center"}>
                        <span
                            className={"ml-1 text-xl"}>{GameStore.attemptNumber}/{GameStore.attemptHistory.length}</span>
                    </div>
                </div>
                <div className={"w-full mt-4"}>
                    <p className={"font-bold"}>Загаданное слово: </p>
                    <p className={"text-xl mt-1 uppercase tracking-widest"}>{GameStore.word}</p>
                </div>
            </div>
        </div>

        <Spacing size={24}><Separator/></Spacing>

        <div className={"flex justify-center items-center"}>
            <ShareButton/>
        </div>
    </ModalCard>)
});