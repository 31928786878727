import {Icon28ThumbsUpOutline} from "@vkontakte/icons";
import vkBridge from "@vkontakte/vk-bridge";
import {Button, Card, Header, Image, Spacing} from "@vkontakte/vkui";
import React, {useState} from 'react';
import {parseLaunchParams} from "../../utils/launch_params";

// Recommendation - блок с рекомендацией сервиса
export function Recommendation() {
    const params = parseLaunchParams();
    const [isRecommended, setIsRecommended] = useState(params.vk_is_recommended === "1");

    const clickHandler = () => {
        if (isRecommended) return;

        vkBridge.send('VKWebAppRecommend')
            .then(() => {
                setIsRecommended(true)
            })
            .catch((error) => {
                console.log("VKWebAppRecommend: ", error)
            });
    };


    return (
        <>
            <Spacing size={24}/>
            <div className={"flex w-full flex-col max-w-md"}>
                <Card>
                    <div className={"flex flex-row gap-1 px-4"}>
                        <div className={"flex justify-center items-center"}>
                            <Image className={"bg-yellow-400"}>
                                <Icon28ThumbsUpOutline className={"text-gray-800"}/>
                            </Image>
                        </div>
                        <div className={"flex flex-col mb-4"}>
                            {!isRecommended &&
                                <>
                                    <Header multiline={true}
                                            subtitle={<p className={"pt-1 whitespace-pre-wrap"}>Порекомендуйте игру
                                                своим друзям
                                                и соревнуйтесь вместе с ними!</p>}>
                                        Понравилась игра?
                                    </Header>

                                    <div className={"flex ml-4 mt-2 items-center"}>
                                        <Button mode="primary"
                                                className={"rounded bg-yellow-400 hover:bg-yellow-500 text-gray-800 border-solid border-2 border-yellow-400/20"}
                                                onClick={clickHandler}>Рекомендовать сейчас</Button>
                                    </div>
                                </>
                            }

                            {isRecommended &&
                                <>
                                    <Header multiline={true}
                                            subtitle={<p className={"pt-1 whitespace-pre-wrap"}>Спасибо за Вашу доброту <span className={"text-red-700"}>❤</span></p>}>
                                        Вы рекомендуете
                                    </Header>
                                </>
                            }
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
}
