import {observer} from "mobx-react-lite";
import GameStore from "../../store/game";

// Отображает игровое поле с буквами
export const GameBoard = observer(() => {
    const board = GameStore.board;
    const attemptHistory = GameStore.attemptHistory;

    // Заполняем стартовое игровое поле
    const playGround = board.map((row, i) => {
        const letters = row.map((letterInfo, j) => {
            let additionalLetterStyle = "";
            let value = "";

            const history = attemptHistory[i];
            if (history !== null) {
                value = history[j]?.value;

                // Определяем статус букв, чтобы выбрать цвет фона
                if (history[j]?.is_exists === true) {
                    additionalLetterStyle = " bg-white text-gray-800";
                }

                if (history[j]?.is_exactly_exists === true) {
                    additionalLetterStyle = " bg-yellow-300 text-gray-800";
                }
            }

            return (<div
                className={"flex justify-center items-center p-2 text-gray-300 border-solid border-2 border-yellow-100/50 rounded bg-gray-800 uppercase w-[46px] h-[46px]" + additionalLetterStyle}
                key={"letter" + i + "" + j}
            >
                {value}
            </div>);
        });

        return <div className={"flex justify-center gap-3 py-2"} key={"row" + i}>{letters}</div>
    });

    return (<div className={"px-4 py-2 flex flex-col w-full"}>
        {playGround}
    </div>);
});
