import {Icon28Done} from "@vkontakte/icons";
import GameStore, {maxLetters} from "../../store/game";
import {observer} from "mobx-react-lite";

// Кнопка проверки слова
export const DoneButton = observer(({onClick}) => {
    let bgColor = "bg-gray-800 hover:bg-gray-700";

    if (GameStore.attempt.length === maxLetters) {
        bgColor = "bg-green-800 hover:bg-green-700";
    }

    return (<div
        onClick={onClick}
        className={"flex items-center px-1 py-2 sm:px-3 sm:py-2 text-gray-300 border-solid rounded h-[40px] cursor-pointer " + bgColor}>
        <Icon28Done width={20} height={20}/>
    </div>)
})
