import {Icon28BackspaceOutline} from "@vkontakte/icons";
import React from "react";

// Кнопка для удаления введённого символа
export function BackspaceButton({onClick}) {
    return (<div
        onClick={onClick}
        className="flex items-center px-1 py-2 sm:px-3 sm:py-2 text-gray-300 border-solid rounded bg-gray-800 h-[40px] hover:bg-gray-700 cursor-pointer">
        <Icon28BackspaceOutline width={20} height={20}/>
    </div>)
}