import * as React from 'react';
import vkBridge, {parseURLSearchParamsForGetLaunchParams} from '@vkontakte/vk-bridge';
import {useAdaptivity, useInsets} from '@vkontakte/vk-bridge-react';
import {AdaptivityProvider, AppRoot, ConfigProvider} from '@vkontakte/vkui';
import {transformVKBridgeAdaptivity} from './transformers/transformVKBridgeAdaptivity';
import App from './App';
import {createRoot} from 'react-dom/client';
import {RouterProvider} from "@vkontakte/vk-mini-apps-router";
import {router} from "./routing/routing";
import "./css/index.css";

// Инициализируем VK Mini App
vkBridge.send('VKWebAppInit');

const Root = () => {
    const vkBridgeInsets = useInsets() || undefined; // Вместо undefined можно задать значение по умолчанию
    const vkBridgeAdaptivityProps = transformVKBridgeAdaptivity(useAdaptivity()); // Конвертируем значения из VK Bridge в параметры AdaptivityProvider
    const {vk_platform} = parseURLSearchParamsForGetLaunchParams(window.location.search); // [опционально] Платформа может передаваться через URL (см. https://dev.vk.com/mini-apps/development/launch-params#vk_platform)

    return (
        <ConfigProvider
            appearance={"light"}
            platform={vk_platform === 'desktop_web' ? 'android' : undefined}
            isWebView={vkBridge.isWebView()}
            hasCustomPanelHeaderAfter={true} // Резервируем правую часть PanelHeader под кнопки управления VK Mini Apps. Через параметр customPanelHeaderAfterMinWidth можно регулировать ширину этой области (по умолчанию, используется 90)
        >
            <AdaptivityProvider {...vkBridgeAdaptivityProps}>
                <AppRoot mode="full" safeAreaInsets={vkBridgeInsets}>
                    <RouterProvider router={router}>
                        <App/>
                    </RouterProvider>
                </AppRoot>
            </AdaptivityProvider>
        </ConfigProvider>
    );
};

const root = createRoot(document.getElementById("root"));
root.render(<Root/>);

if (process.env.NODE_ENV === "development") {
    import("./eruda").then(({default: eruda}) => {});
}