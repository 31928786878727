import React from "react";

// Представляет одну букву на клавиатуре
export function Letter({onClick, value, isUsed, isExists, isExactlyExists}) {
    let additionalLetterStyle = "";

    // Определяем статус букв, чтобы выбрать цвет фона
    if (isUsed === true) {
        additionalLetterStyle = " bg-slate-600 text-gray-200";
    }

    if (isExists === true) {
        additionalLetterStyle = " bg-white text-gray-800";
    }

    if (isExactlyExists === true) {
        additionalLetterStyle = " bg-yellow-300 text-gray-800";
    }

    return (<div
        onClick={onClick}
        data-letter={value}
        className={"flex align-center justify-center items-center h-[40px] w-[25px] sm:w-[30px] border-solid rounded uppercase bg-gray-800 hover:bg-gray-700 text-gray-300 hover:text-gray-300 cursor-pointer select-none" + additionalLetterStyle}>
        {value}
    </div>)
}
