import {Icon20ShareOutline} from "@vkontakte/icons";
import {Button} from "@vkontakte/vkui";
import React, {useState} from "react";
import bridge from "@vkontakte/vk-bridge";
import {OpenErrorSnackbar, OpenSuccessSnackbar} from "../SnackBar/SnackBar";

// ShareButton - кнопка для "Рассказать друзьям"
export const ShareButton = () => {
    const [snackbar, setSnackbar] = useState(null);
    const shareButtonHandler = () => {
        bridge.send('VKWebAppShowWallPostBox', {
            message: 'Приглашаю тебя сыграть в увлекательную интеллектуальную игру «5 букв»! А за сколько попыток ты сможешь отгадать слово?',
            attachments: "https://vk.com/app51765300"
        })
            .then((data) => {
                if (data.post_id) {
                    setSnackbar(OpenSuccessSnackbar("Пост успешно размещён", () => {
                        setSnackbar(null)
                    }));
                }
            })
            .catch((error) => {
                console.log("VKWebAppShowWallPostBox err:", error);
                if (error.error_data.error_code !== 10007 && error.error_data.error_code !== 4) {
                    setSnackbar(OpenErrorSnackbar("Не удалось разместить пост на стене", () => {
                        setSnackbar(null)
                    }));
                }
            });
    }

    return (
        <>
            {snackbar}
            <Button
                    appearance={'overlay'}
                    className={"rounded bg-yellow-400 hover:bg-yellow-500 text-gray-800 border-solid border-2 border-yellow-400/20"}
                    size={"m"}
                    before={<Icon20ShareOutline/>}
                    onClick={shareButtonHandler}>
                Рассказать друзьям
            </Button>
        </>
    );
};