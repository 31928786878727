import vkBridge from "@vkontakte/vk-bridge";

// showAdvertising показывает рекламу пользователю
export const showAdvertising = () => {
    console.log("Проверяем, показывали ли рекламу в текущей сессии?", sessionStorage.getItem("showedAdvertising"));

    // Чтобы не показывать рекламу несколько раз одному пользователю
    if (sessionStorage.getItem("showedAdvertising") !== "1") {
        console.log("Рекламу ещё не показывали");

        // Проверяем наличие рекламы для показа
        vkBridge.send("VKWebAppCheckNativeAds", {ad_format: "interstitial"}).then(resp => {
            if (!resp.result) {
                console.log("Рекламных блоков для показа нет");
                return;
            }

            console.log("Есть рекламные блоки для показа");

            // Отправляем запрос на показ рекламы
            vkBridge.send("VKWebAppShowNativeAds", {ad_format: "interstitial"}).then(resp => {
                console.log("Реклама показана успешно:", resp);
            }).catch(error => {
                console.log("Ошибка при показе рекламы:", error);
            }).finally(() => {
                // Фиксируем показ рекламы в хранилище
                sessionStorage.setItem("showedAdvertising", "1");
            });
        });
    }
}