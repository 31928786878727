// Клавиатура с буквами и кнопками проверки слова и удалением символов
import {observer} from "mobx-react-lite";
import GameStore from "../../store/game";
import {BackspaceButton} from "./BackspaceButton";
import {DoneButton} from "./DoneButton";
import {Letter} from "./Letter";

export const Keyboard = observer(({onChooseLetter, onDoneButton, onBackspaceButton}) => {
    let attemptNumber = GameStore.attemptNumber;
    let attemptHistory = GameStore.attemptHistory;

    const defaultButtons = [[{
        type: "letter", value: "й", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "ц", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "у", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "к", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "е", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "н", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "г", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "ш", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "щ", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "з", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "х", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "ъ", isUsed: false, isExists: false, isExactlyExists: false,
    },], [{
        type: "letter", value: "ф", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "ы", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "в", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "а", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "п", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "р", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "о", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "л", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "д", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "ж", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "э", isUsed: false, isExists: false, isExactlyExists: false,
    },], [{
        type: "done_button",
    }, {
        type: "letter", value: "я", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "ч", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "с", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "м", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "и", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "т", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "ь", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "б", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "letter", value: "ю", isUsed: false, isExists: false, isExactlyExists: false,
    }, {
        type: "backspace_button",
    },],];

    // Сформировать список букв, использованных пользователем
    let userUsedLetters = {};
    for (let i = 0; i < attemptNumber; i++) {
        const history = attemptHistory[i];
        if (!history) break;

        history.map(letter => {
            const old = userUsedLetters[letter.value];
            if (old) {
                userUsedLetters[letter.value] = {
                    "value": letter.value,
                    "is_used": old.is_used === true || letter.is_used === true,
                    "is_exists": old.is_exists === true || letter.is_exists === true,
                    "is_exactly_exists": old.is_exactly_exists === true || letter.is_exactly_exists === true,
                };
            } else {
                userUsedLetters[letter.value] = letter;
            }
        });
    }

    // Формируем клавиатуру
    const keyboard = defaultButtons.map((row, i) => {
        const buttonRow = row.map((button, j) => {
            // Настраиваем внешний вид кнопок-букв
            if (button.type === "letter") {
                const usedLetterInfo = userUsedLetters[button.value];

                if (usedLetterInfo) {
                    button.isUsed = usedLetterInfo.is_used === true;
                    button.isExists = usedLetterInfo.is_exists === true;
                    button.isExactlyExists = usedLetterInfo.is_exactly_exists === true;
                }
            }

            return <div key={"button" + j}>
                {button.type === "letter" && <Letter onClick={onChooseLetter}
                                                     value={button.value}
                                                     isUsed={button.isUsed}
                                                     isExists={button.isExists}
                                                     isExactlyExists={button.isExactlyExists}
                                                     key={button.value}/>}

                {button.type === "done_button" && <DoneButton onClick={onDoneButton} key={"done_button"}/>}
                {button.type === "backspace_button" &&
                    <BackspaceButton onClick={onBackspaceButton} key={"done_button"}/>}
            </div>;
        });

        return (<div className={"flex justify-center max-[340px]:gap-1 gap-1.5 sm:gap-2 py-2"} key={"row" + i}>
            {buttonRow}
        </div>)
    });

    return (<div className={"px-4 py-2 flex flex-col w-full"}>
        {keyboard}
    </div>)
});
