import {createHashParamRouter} from '@vkontakte/vk-mini-apps-router';
import {MODAL_GAME_SUCCESS_FINISH} from "../modals/Game/SucessFinishGame";
export const DEFAULT_VIEW = "default_view"
export const HOME_PANEL = "home_panel"
export const GAME_PANEL = "game_panel"
export const RATING_PANEL = "rating_panel"
export const GAME_SUCCESS_FINISH_MODAL = "game_success_finish_modal";
export const GAME_FAILED_FINISH_MODAL = "game_failed_finish_modal";

export const router = createHashParamRouter([
    // Страницы
    {
        path: '/',
        panel: HOME_PANEL,
        view: DEFAULT_VIEW,
    },
    {
        path: '/game',
        panel: GAME_PANEL,
        view: DEFAULT_VIEW,
    },
    {
        path: '/rating',
        panel: RATING_PANEL,
        view: DEFAULT_VIEW,
    },
]);
