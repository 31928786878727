import { makeAutoObservable } from "mobx";

// Стоимость новой игры
export const gamePrice = 100;

// Число попыток отгадать слово по умолчанию
export const maxBasicAttempts = 5;

// Максимальное число попыток отгадать слово
export const maxAttempts = 6;

// Максимальное число букв в слове
export const maxLetters = 5;

// Максимальное число игр в день одним игроком
export const maxBasicDaileAttempts = 1;

// Настройка клавиатуры
const basicLetterInfo = {
    type: "letter", value: "", isUsed: false, isExists: false, isExactlyExists: false,
};
const row = Array(maxLetters).fill(basicLetterInfo);
const defaultBoardTemplate = Array(maxBasicAttempts).fill(row);

class Game {
    // Флаг, выиграл ли пользователь
    isWin = null;

    // Номер попытки отгадать слово
    attemptNumber = 0;

    // Состояние конкретной попытки пользователя
    attempt = [];

    // История попыток пользователя
    attemptHistory = Array(maxBasicAttempts).fill(null);

    // Игровое поле
    board = defaultBoardTemplate;

    // Количество игр в день
    dailyAttempts = maxBasicDaileAttempts;

    // Количество заработанных монет по итогам игры
    earnedCoins = 0;

    // Идентификатор игры
    gameID = "";

    // Загаданное слово
    word = "";

    constructor() {
        makeAutoObservable(this)
    }

    setIsWin(value) {
        this.isWin = value;
    }

    incrementAttemptNumber(state) {
        this.attemptNumber += 1;
    }

    addLetterToAttempt(value) {
        const data = {
            value: value, isUsed: false, isExists: false, isExactlyExists: false,
        };

        this.attempt.push(data);
        this.attemptHistory[this.attemptNumber] = this.attempt;
    }

    deleteLastLetterFromAttempt() {
        this.attempt.pop();
        this.attemptHistory[this.attemptNumber] = this.attempt;
    }

    flushAttempt() {
        this.attempt = [];
    }

    addAttemptToHistory(value) {
        this.attemptHistory[this.attemptNumber] = value;
    }

    addBonusAttempt() {
        this.attemptHistory.push(null);
        this.board.push(row);
    }

    setDailyAttempts(value) {
        this.dailyAttempts = value;
    }

    setEarnedCoins(value) {
        this.earnedCoins = value;
    }

    setIsLoading(value) {
        this.isLoading = value;
    }

    setGameID(value) {
        this.gameID = value;
    }

    setWord(value) {
        this.word = value;
    }

    flush() {
        this.isWin = null;
        this.attemptNumber = 0;
        this.attempt = [];
        this.attemptHistory = Array(maxBasicAttempts).fill(null);
        this.board = defaultBoardTemplate;
        this.gameID = "";
        this.word = "";
    }
}

const GameStore = new Game();
export default GameStore;