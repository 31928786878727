import React, {useEffect, useState} from 'react';
import {numberWordSuffix} from "../../utils/word";

// Обратный отсчет до
const Countdown = ({date}) => {
    const calculateTimeLeft = () => {
        const difference = +date - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const [year] = useState(date.getFullYear());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    });

    const timerComponents = [];
    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
        );
    });

    return (
        <div className={"flex px-4 flex-col"}>
            <div className={"flex flex-row text-gray-300"}>
                <p className={"text-gray-800 text-sm"}>Жизни на сегодня закончились. Следующая игра будет доступна через:</p>
            </div>

            <div className={"p-4 flex flex-row mb-3 justify-center"}>
                <div className={"flex flex-col items-center justify-center h-12 w-20 px-4"}>
                    <div className={"text-2xl font-bold"}>{timeLeft.hours}</div>
                    <span>{ numberWordSuffix(timeLeft.hours, ["час", "часа", "часов"]) }</span>
                </div>
                <div className={"flex flex-col items-center justify-center h-12 w-20 px-4"}>
                    <div className={"text-2xl font-bold"}>{timeLeft.minutes}</div>
                    <span>{ numberWordSuffix(timeLeft.minutes, ["минуту", "минуты", "минут"]) }</span>
                </div>
                <div className={"flex flex-col items-center justify-center h-12 w-20 px-4"}>
                    <div className={"text-2xl font-bold"}>{timeLeft.seconds}</div>
                    <span>{ numberWordSuffix(timeLeft.seconds, ["секунду", "секунды", "секунд"]) }</span>
                </div>
            </div>
        </div>
    );
}

export default Countdown;