import React from "react";
import {Snackbar} from "@vkontakte/vkui";
import {Icon28CheckCircleOutline, Icon28ErrorCircleOutline} from "@vkontakte/icons";

// OpenSnackbar - открывает бар с текстом ошибки
export function OpenErrorSnackbar(text, onClose) {
    return (
        <Snackbar
            before={<Icon28ErrorCircleOutline fill="var(--vkui--color_icon_negative)"/>}
            onClose={() => onClose()}
        >
            {text}
        </Snackbar>
    );
}

// OpenSuccessSnackbar - открывает бар с текстом успешного сообщения
export function OpenSuccessSnackbar(text, onClose) {
    return (
        <Snackbar
            before={<Icon28CheckCircleOutline fill="var(--vkui--color_accent_green)"/>}
            onClose={() => onClose()}
        >
            {text}
        </Snackbar>
    );
}
