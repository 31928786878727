// Logotype логотип для главного экрана
export function Logotype() {
    const style = "flex justify-center items-center p-2 border-solid border-2 border-gray-100/50 rounded bg-gray-800 uppercase w-[32px] h-[32px] bg-yellow-300 text-gray-800";
    return (<div className={"flex w-full justify-center items-center"}>
        <div className={"flex w-full max-w-md gap-2"}>
            <div className={style}>5</div>
            <div className={style}>б</div>
            <div className={style}>у</div>
            <div className={style}>к</div>
            <div className={style}>в</div>
        </div>
    </div>);
}