import {createURL} from "./client";
import axios from "axios";

// createGame создаёт новую игру
export async function createGame(playerID) {
    return await axios.post(createURL("/api/game/?vk_user_id=" + parseInt(playerID)));
}

// checkUserWord проверяет введённое пользователем слово
export async function checkUserWord(playerID, gameID, word) {
    return await axios.post(createURL("/api/game/check-word?vk_user_id=" + parseInt(playerID)), {
        game_id: gameID,
        word: word,
    });
}

// addNewAttempt добавляет новую попытку
export async function addNewAttempt(playerID, gameID) {
    return await axios.post(createURL("/api/game/add-attempt?vk_user_id=" + parseInt(playerID)), {
        game_id: gameID,
    });
}


// checkRunningGameExistence проверяет существование запущенной игры у пользователя
export async function checkRunningGameExistence() {
    return await axios.get(createURL("/api/game/existence"));
}