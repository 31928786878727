import {b64EncodeUnicode} from "../utils/b64";

export const apiUrl = process.env.NODE_ENV === 'production' ?
    process.env.REACT_APP_PROD_API_URL :
    process.env.REACT_APP_DEV_API_URL;

// createURL создаёт URL с параметрами запуска для отправки
export function createURL(path) {
    const launchParams = b64EncodeUnicode(window.location.href);

    const url = new URL(apiUrl + path);
    if (url.search.length === 0) {
        return url + "?p=" + launchParams;
    }

    return url + "&p=" + launchParams;
}