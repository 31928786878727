import {useFirstPageCheck, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {Avatar, Card, Cell, Header, PanelHeader, PanelHeaderBack, Spacing, Spinner} from '@vkontakte/vkui';
import React, {useEffect, useState} from 'react';
import {getPlayerRating, getTopWinners} from "../services/player";

function Rating() {
    // Роутинг
    const routeNavigator = useRouteNavigator();
    const isFirstPage = useFirstPageCheck();

    const handleBackButton = () => {
        return isFirstPage ? routeNavigator.push('/') : routeNavigator.back();
    }
    return (<>
        <PanelHeader
            before={<PanelHeaderBack className={"[&>svg]:p-0"} onClick={handleBackButton}/>}
            separator={null}
        >
            Рейтинг
        </PanelHeader>

        <div className={"flex flex-col p-4 items-center"}>
            <PlayerList/>
        </div>
    </>)
}

function PlayerList() {
    const [players, setPlayers] = useState([]);
    const [currentPlayer, setCurrentPlayer] = useState({});
    const [currentPlayerRank, setCurrentPlayerRank] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getPlayerRating().then(resp => {
            const data = resp.data.response;
            setCurrentPlayer(data.player);
            setCurrentPlayerRank(data.player_rank);

            const players = data.winners.map(player => {
                return (<Cell before={<Avatar src={player.avatar}/>}
                              key={player.id}
                              subtitle={"Побед: " + player.won_games + " Поражений: " + player.lose_games}
                              disabled={true}
                >
                    {player.name}
                </Cell>)
            })
            setPlayers(players);
        }).catch(err => {
            console.log("getTopWinners", err);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    return (<div className={"flex w-full flex-col max-w-md"}>
        {/* Ожидаем загрузку данных */}
        {isLoading === true && <Spinner size={"large"}/>}

        {/* Отображаем контент */}
        {!isLoading && <>
                {/*Вывод рейтинга*/}
                <Card>
                    <Spacing size={12}/>
                    {players}
                    <Spacing size={12}/>
                </Card>

                <Spacing size={24}/>

                {/* Вывод информации о самом игроке */}
                <Card>
                    <Header subtitle={"Позиция в рейтинге: " + currentPlayerRank}>
                        Ваш рейтинг
                    </Header>
                    <Cell before={<Avatar src={currentPlayer.avatar}/>}
                          key={currentPlayer.id}
                          subtitle={"Побед: " + currentPlayer.won_games + " Поражений: " + currentPlayer.lose_games}
                          disabled={true}
                    >
                        {currentPlayer.name}
                    </Cell>
                    <Spacing size={12}/>
                </Card>
            </>
        }
    </div>);
}

export default Rating;
