import {Icon28Notifications} from "@vkontakte/icons";
import vkBridge from "@vkontakte/vk-bridge";
import {Button, Card, Header, Image} from "@vkontakte/vkui";
import React, {useState} from 'react';
import {parseLaunchParams} from "../../utils/launch_params";


// Subscription - блок с подпиской на push уведомления
export function Subscription() {
    const params = parseLaunchParams();
    const [isSubscribed, setIsSubscribed] = useState(params.vk_are_notifications_enabled === "1");

    const clickHandler = () => {
        if (!isSubscribed) {
            vkBridge.send('VKWebAppAllowNotifications')
                .then(() => {setIsSubscribed(true)})
                .catch((error) => {console.log("VKWebAppAllowNotifications: ", error)});
            return;
        }

        vkBridge.send('VKWebAppDenyNotifications')
            .then(() => {setIsSubscribed(false)})
            .catch((error) => {console.log("VKWebAppDenyNotifications error: ", error)});
    };

    return (<div className={"flex w-full flex-col max-w-md"}>
        <Card>
            <div className={"flex flex-row gap-1 px-4"}>
                <div className={"flex justify-center items-center"}>
                    <Image className={"bg-yellow-400"}>
                        <Icon28Notifications className={"text-gray-800"}/>
                    </Image>
                </div>
                <div className={"flex flex-col mb-4"}>
                    <Header multiline={true}
                            subtitle={<p className={"pt-1 whitespace-pre-wrap"}>Подпишись и узнавай о новых событиях и
                                бонусах первым!</p>}>
                        Подпишись на уведомления
                    </Header>

                    {!isSubscribed && <div className={"flex ml-4 mt-2 items-center"}>
                        <Button mode="primary"
                                className={"rounded bg-yellow-400 hover:bg-yellow-500 text-gray-800 border-solid border-2 border-yellow-400/20"}
                                onClick={clickHandler}>Подписаться сейчас</Button>
                    </div>}

                    {isSubscribed && <div className={"flex ml-4 mt-2 items-center"}>
                        <Button mode="primary"
                                className={"rounded bg-transparent border-solid border-2 border-gray-300 text-gray-800"}
                                onClick={clickHandler}>Отписаться</Button>
                    </div>}
                </div>
            </div>
        </Card>
    </div>);
}
